.swiper {
    width: 100%;
    height: 600px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-button-prev, .swiper-button-next {
    color: #be7419;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background-color: #fff !important;
  }

  .slider-bg {
    background: url('../public/img/slider-food.png') no-repeat center center;
    background-size: cover;
  }
  
  @media (max-width: 460px) {
    .slider-bg {
      background: url('../public/img/mobile-slider.png') no-repeat center center;
      background-size: contain;
    }
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px; /* Swiper'ın altında olmasını sağlar */
    width: 100%;
    cursor: pointer;
  }
  
  .arrow-icon {
    font-size: 2rem;
    color: #F9A225; /* Ok ikonunun rengi */
    animation: bounce 2s infinite; /* Hareket animasyonu */
    border: 3px solid #F9A225; /* Ok ikonunun kenarlık rengi */
    border-radius: 100%;
  }
  
  /* Ok ikonunun aşağı yukarı hareket animasyonu */
  @keyframes bounce {
    0%, 20%, 50%, 55%, 60% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-1px);
    }
  }
  
  #content {
    /* Diğer içerik için stil buraya eklenebilir */
    padding: 20px;
  }

  .swiper-container {
  max-width: 100%;
  margin: 0 auto;
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* Mobile specific styles */
@media (max-width: 640px) {
  .swiper-container {
    max-width: 90%; /* Adjust as needed */
    height: 300px;
    margin: 0 auto;
  }

  .swiper-slide img {
    max-width: 100%;
    height: auto;
  }

  .service-item p {
    padding: 8px; /* Adjust padding for mobile view */
    font-size: 14px; /* Smaller font size for mobile */
  }
}
